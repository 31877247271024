// $(window).load ->
//    $('.scroll-container').mCustomScrollbar()

window.$ = window.jQuery = require('jquery');

require('uikit')
require('uikit/dist/js/components/slideshow')
require('uikit/dist/js/components/slideshow-fx')
require('uikit/dist/js/components/slider')
require('uikit/dist/js/components/slideset')
